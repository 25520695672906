@tailwind base;
@tailwind components;
@tailwind utilities;


.clip {
clip-path: polygon(0% 0%, 100% 0%, 100% 48.75%, 100% 85.33%, 0% 100%);
  /* background-color: #000000 */
}

/* Import the Lato font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* Apply the Lato font to the body and other elements */
* {
  font-family: 'Lato', sans-serif;
}

.roboto {
  font-family: 'Roboto', sans-serif;
}

.inter {
  font-family: 'Inter', sans-serif;
}